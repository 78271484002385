<template>
  <div class="footer">
    <section class="kedaya-describe">
      <div class="kedaya-logo">
        <img src="@/assets/icon/footer_icon.png" alt="">
      </div>
      <h4 class="describe-text">
        可搭吖，每次“搭吖”都是新故事的开始！
        这里不仅是社交平台，更是发现自我、结交闺蜜的魔法乐园。<br>
        一起来探索世界，找到更多志同道合的姐妹搭子吧！
      </h4>
      <ul class="hyperlink mb20">
        <li class="hyperlink-item">
          <img src="@/assets/icon/wx_icon.png" alt="">
          <div class="qr-code">
            <img src="@/assets/img/wx_qrcode.png" alt="">
          </div>
        </li>
        <li class="hyperlink-item">
          <a target="_blank" href="https://v.douyin.com/iMDAycFk/">
            <img src="@/assets/icon/dy_icon.png" alt="">
          </a>
        </li>
        <li class="hyperlink-item">
          <a target="_blank" href="https://www.xiaohongshu.com/user/profile/6645a87e00000000030337d9">
            <img src="@/assets/icon/xhs_icon.png" alt="">
          </a>
        </li>
      </ul>
      <h4 class="describe-text">
        Copyrights © 2024 可搭吖APP All Rights Reserved. <a target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery">粤ICP备14024433号-16</a>
      </h4>
    </section>
    <section class="contact-box">
      <h3>联系方式</h3>
      <h5 class="mb4">邮箱：support@psydusk.com.cn</h5>
      <h5 class="mb24">地址：深圳市南山区高新南七道惠恒大厦二期708</h5>
      <div class="system">
        <li class="mr28 btn-box" @mouseover="generateQRCode(appleDownloadUrl)">
          <div class="iosBtn"></div>
          <span>App Store</span>
          <div class="qrimg qrios">
            <img :src="qrCodeDataUrl" alt="">
          </div>
        </li>
        <li class="btn-box" @mouseover="generateQRCode(androidDownloadUrl)">
          <div class="androidBtn"></div>
          <span>Android</span>
          <div class="qrimg qrandroid">
            <img :src="qrCodeDataUrl" alt="">
          </div>
        </li>
      </div>
    </section>
  </div>
</template>
<script>
import { GetEnableLatestAppVersion } from '@/api/index'
import QRCode from 'qrcode'
export default {
  name: 'Footer',
  data() {
    return {
      appleDownloadUrl: '',
      androidDownloadUrl: '',
      qrCodeDataUrl: null,
    }
  },
  mounted() {
    this.getEnableLatestAppVersion(1) // 获取apple 下载地址
    this.getEnableLatestAppVersion(2) // 获取android 下载地址
  },
  methods: {
    getEnableLatestAppVersion(kind) {
      GetEnableLatestAppVersion({ kind }).then((res) => {
        if (kind == 1) {
          this.appleDownloadUrl = res.app_version?.url || ''
        } else {
          this.androidDownloadUrl = res.app_version?.url || ''
        }
        this.$emit('downloadUrl', {
          kind,
          url: res.app_version?.url || ''
        })
      })
    },
    generateQRCode(url) {
      try {
        const dataUrl = QRCode.toDataURL(url, { margin: 0 });
        dataUrl.then((res) => {
          this.qrCodeDataUrl = res;
        })

      } catch (error) {
        console.error("Error generating QR Code:", error);
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.footer {
  padding: 50rem 380rem 50rem 320rem;
  background: #333333;
  display: flex;
  justify-content: space-between;

  .kedaya-describe {
    .kedaya-logo {
      width: 94rem;
      height: 28rem;
      margin-bottom: 20rem;
    }

    .describe-text {
      width: 666rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      font-size: 14rem;
      color: rgba(255, 255, 255, .7);
      line-height: 22rem;
      margin-bottom: 16rem;

      a {
        color: rgba(255, 255, 255, .7);
        text-decoration: underline;
      }
    }

    .hyperlink {
      display: flex;

      .hyperlink-item {
        width: 28rem;
        height: 28rem;
        margin-right: 16rem;
        position: relative;
        cursor: pointer;

        .qr-code {
          display: none;
          position: absolute;
          height: 150rem;
          top: -160rem;

          img {
            width: auto;
            height: 150rem;
            object-fit: contain;
          }
        }
      }

      .hyperlink-item:hover {
        .qr-code {
          display: block;
        }
      }

      .hyperlink-item:last-child {
        margin-right: 0;
      }
    }
  }

  .contact-box {
    h3 {
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      font-size: 18rem;
      color: #FFFFFF;
      line-height: 21rem;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-bottom: 21rem;
    }

    h5 {
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      font-size: 14rem;
      color: rgba(255, 255, 255, .7);
      line-height: 22rem;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    .system {
      display: flex;

      .btn-box {
        display: flex;
        align-items: center;
        width: 120rem;
        height: 36rem;
        border-radius: 9rem;
        line-height: 36rem;
        font-size: 11rem;
        cursor: pointer;
        border: 1rem solid #FFFFFF;
        font-weight: 500;
        color: #FFFFFF;
        padding: 0 17rem;
        position: relative;

        .iosBtn {
          width: 20rem;
          height: 20rem;
          background: url('../../assets/img/app_store2.png');
          background-size: 100% 100%;
          margin-right: 10rem;
        }

        .androidBtn {
          width: 20rem;
          height: 20rem;
          margin-right: 10rem;
          background: url('../../assets/img/android2.png');
          background-size: 100% 100%;
        }

        .qrimg {
          display: none;
          width: 120rem;
          height: 120rem;
          padding: 5rem;
          background: #ffffff;
          border-radius: 5rem;
        }
      }

      .btn-box:hover {
        border: 1rem solid #333333;
        color: #333333;
        background: #ffffff;

        .androidBtn {
          background: url('../../assets/img/android.png');
          background-size: 100% 100%;
        }

        .iosBtn {
          background: url('../../assets/img/app_store.png');
          background-size: 100% 100%;
        }

        .qrimg {
          display: block;

        }

        .qrandroid {
          position: absolute;
          top: -130rem;
          left: 0rem;
        }

        .qrios {
          position: absolute;
          top: -130rem;
          left: 0rem;
        }
      }
    }
  }


}
</style>