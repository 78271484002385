<template>
  <div :class="{ 'nav': true, 'Hidden': isHidden }">
    <section class="nav-box align-center justify-between ">
      <div class="logo-box" @click="$router.push({ name: 'Home' })">
        <img src="@/assets/icon/logo_icon.png" alt="">
      </div>
      <ul class="align-center">
        <li class="nav-tab" v-for="(item, index) in routerTab" :key="index" @click="routerTabClick(item)">
          <span>
            {{ item.name }}
          </span>
          <i v-if="item.path == ''" class="el-icon-caret-bottom"></i>
          <ul class="nav-tab-sub" v-if="item.path == ''">
            <li class="nav-tab-sub-item align-center justify-center" v-for="(itemB, indexB) in item.subset"
              @click.stop="routerTabClick(itemB)" :key="indexB">
              <img class="subItem-icon" :src="itemB.icon" alt="">
              <span>{{ itemB.name }}</span>
            </li>
          </ul>
        </li>
      </ul>

    </section>
  </div>
</template>
<script>
export default {
  name: 'Nav',
  data() {
    return {
      routerTab: [
        { name: '首页', path: 'Home' },
        // { name: '关于APP', path: '/category' },
        { name: '联系我们', path: 'ConnectUs' },
        {
          name: '协议', path: '',
          subset: [
            { name: '用户协议', icon: require('@/assets/icon/user_icon.png'), path: 'Agreement' },
            { name: '隐私政策', icon: require('@/assets/icon/privacy_icon.png'), path: 'Policy' },
            { name: '社区规范', icon: require('@/assets/icon/community_icon.png'), path: 'Standard', }
          ]
        },
      ],
      tabSubShow: false,
      isHidden: false
    }
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    routerTabClick(item) {
      if (!item.path) {
        this.tabSubShow = !this.tabSubShow
      } else if (item.path == this.$route.name) {
        this.scrollToTop()
      }
      else {
        this.$router.push({
          name: item.path
        })
      }
    },
    scrollToTop() {
      this.$nextTick(() => {
        window.scrollTo(0, 0); // 或者 document.documentElement.scrollTop = 0;
      });
    },
    handleScroll() {
      const currentScrollPosition = window.pageYOffset;
      if (
        currentScrollPosition > 150
      ) {
        this.isHidden = true
      } else {
        this.isHidden = false
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.nav {
  width: 100%;
  background: none;
  position: fixed;
  top: 0;
  left: 0;

  .nav-box {
    padding: 0 80px;
    height: 80px;

    .logo-box {
      width: 32px;
      height: 32px;
      margin-right: 50px;
      cursor: pointer;
    }

    .nav-tab {
      padding: 10px;
      margin-right: 50px;
      cursor: pointer;
      font-family: PingFangTC-Regular, PingFang TC;
      font-weight: 400;
      font-size: 18px;
      color: #333333;
      line-height: 24px;
      text-align: center;
      position: relative;

      .nav-tab-sub {
        display: none;
        padding: 10px 16px;
        background: #FFFFFF;
        box-shadow: 0px 0px 30px 0px rgba(0, 128, 128, 0.08);
        border-radius: 16px;
        position: absolute;
        top: 40px;
        left: -58px;
        white-space: nowrap;

        .nav-tab-sub-item {
          width: 134px;
          height: 60px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
          line-height: 60px;

          .subItem-icon {
            width: 22px;
            height: 22px;
            margin-right: 12px;
          }
        }
      }
    }

    .nav-tab:hover .nav-tab-sub {
      display: block;
    }

    .nav-tab:last-child {
      margin-right: 0;
    }
  }
}

.Hidden {
  background: #ffffff;
  z-index: 9;
}
</style>